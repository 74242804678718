$break-small: 320px;
$break-large: 1700px;
$break-medium: 1200px;

.w-100 {
  width: 100%;
}

p {
  font-family: "Source Sans Pro", -apple-system, sans-serif;
}

.international-buttons {
  p {
    color: #3f3d4b;
    padding-top: 0;
    margin-top: 0;
  }
}

.us-state {
  color: #3f3d4b;
  background-color: #fff;
  padding: 16px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 16px;
  padding-right: 3rem;
  height: 3rem;
  border: 1px solid transparent;
  border-color: #ccc;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  font-family: "Source Sans Pro", -apple-system, sans-serif;
  font-size: 1rem;
  line-height: 1;
}

.App {
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
  line-height: 1.428;
}

.App,
#root,
body,
html {
  height: 100%;
}
#root > :first-child {
  height: 100%;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.btn {
  p {
    font-weight: bold;
  }
}

.btn-primary {
  color: #fff;
  background-color: #332211 !important;
  border-color: #332211 !important;
  &:hover {
    opacity: 0.9;
  }
}

.btn-outline-primary {
  color: #b4600b !important;
  border-color: #b4600b !important;
  &:hover {
    color: white !important;

    background-color: #b4600b !important;
  }
}

.coffeeModal {
  h3 {
    display: inline;
    color: #332211;
  }
  .available {
    float: right;
  }
}

.type {
  div,
  select {
    width: 100%;
  }
}

.buy {
  label {
    div {
      width: 100%;
    }
  }
}

.uniswap {
  color: #fe6dde;

  a & {
    margin-left: 2px;
    font-weight: bold;
  }

  &:hover {
    color: #fe6dde;
    text-decoration: none;
  }
}

.affogato-link {
  font-weight: bold;
  color: #b4600b;

  &:hover {
    color: #743e08;
    text-decoration: none;
  }
}

@media screen and (min-width: $break-small) {
  .App {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrapper {
    h1 {
      font-size: 32px;
      color: #332211;
    }

    h4 {
      margin-top: 20px;
      font-size: 20px;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding-top: 20px;
    padding-bottom: 30px;

    img.logo {
      width: 150px;
      margin-top: 6px;
    }

    .btn {
      border-radius: 6px;
      padding: 0.75rem;
      height: 48px;
    }

    .wallet-details {
      display: flex;
    }

    .tokens {
      cursor: auto;
      transform: scale(1);
      padding: 0.75rem;
      border-radius: 6px;
      transition: transform 0.3s ease 0s;
      color: #2b2b2b;

      font-family: Inter, sans-serif;
      opacity: 1;
      margin-top: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;

      img {
        height: 20px;
        margin-right: 5px;
      }

      p {
        color: #2b2b2b;
        font-weight: bold;
      }
    }

    .address {
      background-color: #f1f2f6;
      cursor: auto;
      transform: scale(1);
      padding: 0.75rem;
      border-radius: 6px;
      transition: transform 0.3s ease 0s;
      color: #2b2b2b;
      border: 0;
      font-family: Inter, sans-serif;
      opacity: 1;
      margin-top: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;

      p {
        color: #2b2b2b;
        font-weight: bold;
      }

      .circle {
        display: initial;
        width: 12px;
        height: 12px;
        margin-left: 12px;
        border-radius: 100%;

        &.connected-1 {
          background-color: #038789;
        }

        &.connected-4 {
          background-color: #ebb33f;
        }
      }
    }

    .button-wrapper {
      text-align: center;
      display: flex;
      flex-direction: column;

      p {
        float: none;
      }

      .walletConnect {
        margin-top: 10px;
        margin-left: 0px;
      }
    }
  }

  .coffee-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }

  .coffee-card {
    border: 1px solid transparent;
    border-bottom: 1px transparent;
    border-color: #eee;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    padding: 0px;
    background-color: #fcf6f0;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    min-width: 16px;
    min-height: 16px;
    position: relative;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1);
    min-height: 450px;
    text-align: center;
    padding-bottom: 30px;

    .coffee-image {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-image: url("assets/coffee-bag.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 300px;
      padding: 0;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .product-title {
      padding: 0px 20px;
      text-align: center;

      h2 {
        margin-top: 20px;
        text-align: center;
        font-size: 28px;
      }

      h4 {
        font-size: 20px;
        text-align: center;
      }

      h6 {
        font-size: 15px;
        text-align: center;
      }

      p {
        text-align: center;
        margin-top: 20px;
      }

      a {
        font-weight: bold;
        color: #b4600b;
      }
    }

    .product-details {
      padding: 0px 20px;
      text-align: center;

      a.cup-profile {
        font-weight: bold;
        color: #b4600b;
      }

      ul {
        list-style-type: none;
        text-align: center;
        padding-left: 0;

        li {
          margin-bottom: 20px;

          h5 {
            color: #332211;
          }
        }

        img {
          margin-top: 5px;
          height: 50px;
        }
      }

      button,
      a {
        width: 50%;
        margin-top: 20px;
      }

      .buy {
        display: none;
        // border-top-right-radius: 0;
        // border-bottom-right-radius: 0;
        // span {
        //   margin-right: 2px;
        // }
      }

      .trade {
        display: none;
        // background: #fe6dde;
        // color: white;
        // border-top-left-radius: 0;
        // border-bottom-left-radius: 0;

        // span {
        //   margin-right: 2px;
        // }
      }
    }
  }

  .coffee-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .button-wrapper {
      width: 100%;

      button,
      a {
        width: 100%;
      }
      a:hover {
        color: white;
      }
    }

    .buy {
      border-radius: 0;
    }

    .sell {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .credits {
    padding-bottom: 20px;
    p {
      margin-bottom: 0px;
      margin-top: 40px;
      font-weight: bold;
      font-size: small;
    }

    .logos {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      b {
        margin-left: 40px;
        margin-right: 40px;
        font-size: 20px;
        display: inline-block;
      }

      img.affogato {
        margin-top: 10px;
        margin-bottom: 10px;
        max-height: 50px;
      }

      img.nativo {
        margin-top: 10px;
        margin-bottom: 10px;
        max-height: 100px;
      }
    }
  }
}

@media screen and (min-width: $break-medium) {
  .App {
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
  }

  .wrapper {
    h1 {
      font-size: 48px;
      color: #332211;
    }

    h4 {
      margin-top: 0px;
      font-size: 20px;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 20px;

    img.logo {
      width: 212px;
      margin-top: 0px;
    }

    .btn {
      border-radius: 6px;
      padding: 0.75rem;
    }

    .injected {
      margin-right: 15px;
    }

    .wallet-details {
      display: flex;
    }

    .tokens {
      cursor: auto;
      transform: scale(1);
      padding: 0.75rem;
      border-radius: 6px;
      transition: transform 0.3s ease 0s;
      color: #2b2b2b;

      font-family: Inter, sans-serif;
      opacity: 1;
      margin-top: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;

      img {
        height: 20px;
        margin-right: 5px;
      }

      p {
        color: #2b2b2b;
        font-weight: bold;
      }
    }

    .address {
      background-color: #f1f2f6;
      cursor: auto;
      transform: scale(1);
      padding: 0.75rem;
      border-radius: 6px;
      transition: transform 0.3s ease 0s;
      color: #2b2b2b;
      border: 0;
      font-family: Inter, sans-serif;
      opacity: 1;
      margin-top: 7px;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: #2b2b2b;
        font-weight: bold;
      }

      .circle {
        display: initial;
        width: 12px;
        height: 12px;
        margin-left: 6px;
        border-radius: 100%;

        &.connected-1 {
          background-color: #038789;
        }

        &.connected-4 {
          background-color: #ebb33f;
        }
      }
    }
    .button-wrapper {
      text-align: center;
      display: flex;

      .walletConnect {
        margin-top: 0px;
      }

      p {
        float: none;
      }

      flex-direction: row;
    }
  }

  .coffee-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .coffee-card {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 100%;
    padding: 0px;
    background-color: #fcf6f0;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: auto;
    min-width: 16px;
    min-height: 16px;
    position: relative;
    border: 1px solid transparent;
    border-bottom: 1px transparent;
    border-color: #eee;
    border-radius: 10px;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1);
    min-height: 450px;
    padding-right: 42px;

    .coffee-image {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 0px;
      grid-column-start: 1;
      grid-column-end: 3;
      background-image: url("assets/coffee-bag.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: auto;
    }

    .product-title {
      padding-top: 42px;
      padding-left: 43px;
      padding-right: 0px;

      h2 {
        margin-top: 20px;
        text-align: left;
      }

      h4 {
        text-align: left;
      }
      h6 {
        text-align: left;
      }

      p {
        text-align: left;
        margin-top: 20px;
      }

      a {
        font-weight: bold;
        color: #b4600b;
      }
    }

    .product-details {
      margin-left: 20px;
      text-align: left;
      padding-top: 42px;
      padding-left: 23px;

      ul {
        list-style-type: none;
        text-align: left;
        padding-left: 0;

        li {
          margin-bottom: 20px;

          h5 {
            color: #332211;
          }
        }

        img {
          margin-top: 5px;
          height: 50px;
        }
      }

      button,
      a {
        width: 100%;
        margin-top: 10px;
      }

      .button-wrapper {
        display: grid;
        grid-template-columns: 100px 100px;
        grid-template-rows: auto auto;
        grid-column-gap: 5px;
        margin-bottom: 20px;

        .redeem {
          grid-column-start: -1;
          grid-column-end: 1;
          width: 100%;
          display: flex;
        }

        .buy {
          width: 100%;
          display: flex;
          grid-column-start: -1;
          grid-column-end: 1;

          span {
            margin-right: 2px;
          }
        }
        // .trade {
        //   width: 100%;
        //   display: flex;

        //   span {
        //     margin-right: 2px;
        //   }
        // }
      }
    }
  }

  .coffee-actions {
    display: none;
    // flex-direction: column;
    // justify-content: space-between;

    // .button-wrapper {
    //   width: 100%;
    //   button {
    //     width: 100%;
    //   }
    // }

    // .buy {
    //   border-radius: 0;
    // }

    // .sell {
    //   border-top-left-radius: 0;
    //   border-top-right-radius: 0;
    // }
  }

  .credits {
    padding-bottom: 0px;
    p {
      margin-bottom: 0px;
      margin-top: 40px;
      font-weight: bold;
      font-size: small;
    }
    .logos {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      b {
        font-size: small;
        margin-left: 20px;
        margin-right: 20px;
        display: inline-block;
      }

      img.affogato {
        margin-top: 0px;
        margin-bottom: 0px;
        height: 40px;
      }

      img.nativo {
        margin-top: 0px;
        margin-bottom: 0px;
        height: 50px;
      }
    }
  }
}

@media screen and (min-width: $break-large) {
  .coffee-container {
    width: 80%;
  }
  .coffee-card {
    width: 85%;
  }
}
